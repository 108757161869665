import * as React from "react"
import { Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

// styles
import "../styles/global.css";
import "../styles/page404.css";
import "@fontsource/sora/300.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";


// markup
const NotFoundPage = () => {
  return (
    <main className="page404">
      <title>Not found</title>
      <div className="pic404"> 
        <StaticImage
          alt="Mayte avatar"
          src="../images/avatarStress.png"
          placeholder="blurred"
          layout="constrained"
          width={150}
          height={150}
        />
      </div>
      
      <h1>Error <br/>404</h1>
      <p>
        Sorry we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code >src/pages/</code>.
            <br />
          </>
        ) : null}
      </p>
      
      
      <Link to="/"><button>Go home</button></Link>
    </main>
  )
}

export default NotFoundPage

